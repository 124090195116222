// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/blockade.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Translation } from "./translations_pb.js";
import { Authorization } from "./authorization_pb.js";
import { OperationState } from "./operations_pb.js";
import { EntityInfo } from "./entities_pb.js";

/**
 * @generated from message avn.connect.v1.BlockadeSkyboxStyleFamily
 */
export class BlockadeSkyboxStyleFamily extends Message<BlockadeSkyboxStyleFamily> {
  /**
   * @generated from field: int32 entity_id = 1;
   */
  entityId = 0;

  /**
   * @generated from field: avn.connect.v1.Translation name = 2;
   */
  name?: Translation;

  /**
   * @generated from field: repeated avn.connect.v1.BlockadeSkyboxStyle styles = 3;
   */
  styles: BlockadeSkyboxStyle[] = [];

  constructor(data?: PartialMessage<BlockadeSkyboxStyleFamily>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.BlockadeSkyboxStyleFamily";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "message", T: Translation },
    { no: 3, name: "styles", kind: "message", T: BlockadeSkyboxStyle, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BlockadeSkyboxStyleFamily {
    return new BlockadeSkyboxStyleFamily().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BlockadeSkyboxStyleFamily {
    return new BlockadeSkyboxStyleFamily().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BlockadeSkyboxStyleFamily {
    return new BlockadeSkyboxStyleFamily().fromJsonString(jsonString, options);
  }

  static equals(a: BlockadeSkyboxStyleFamily | PlainMessage<BlockadeSkyboxStyleFamily> | undefined, b: BlockadeSkyboxStyleFamily | PlainMessage<BlockadeSkyboxStyleFamily> | undefined): boolean {
    return proto3.util.equals(BlockadeSkyboxStyleFamily, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.BlockadeSkyboxStyle
 */
export class BlockadeSkyboxStyle extends Message<BlockadeSkyboxStyle> {
  /**
   * @generated from field: int32 entity_id = 1;
   */
  entityId = 0;

  /**
   * @generated from field: avn.connect.v1.Translation name = 2;
   */
  name?: Translation;

  /**
   * @generated from field: optional avn.connect.v1.Translation description = 3;
   */
  description?: Translation;

  /**
   * @generated from field: int32 max_prompt_size = 4;
   */
  maxPromptSize = 0;

  /**
   * @generated from field: int32 max_negative_prompt_size = 5;
   */
  maxNegativePromptSize = 0;

  /**
   * @generated from field: optional string preview_url = 6;
   */
  previewUrl?: string;

  constructor(data?: PartialMessage<BlockadeSkyboxStyle>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.BlockadeSkyboxStyle";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "message", T: Translation },
    { no: 3, name: "description", kind: "message", T: Translation, opt: true },
    { no: 4, name: "max_prompt_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "max_negative_prompt_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "preview_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BlockadeSkyboxStyle {
    return new BlockadeSkyboxStyle().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BlockadeSkyboxStyle {
    return new BlockadeSkyboxStyle().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BlockadeSkyboxStyle {
    return new BlockadeSkyboxStyle().fromJsonString(jsonString, options);
  }

  static equals(a: BlockadeSkyboxStyle | PlainMessage<BlockadeSkyboxStyle> | undefined, b: BlockadeSkyboxStyle | PlainMessage<BlockadeSkyboxStyle> | undefined): boolean {
    return proto3.util.equals(BlockadeSkyboxStyle, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetSkyboxStyleFamiliesRequest
 */
export class GetSkyboxStyleFamiliesRequest extends Message<GetSkyboxStyleFamiliesRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * Override to the user agent language
   *
   * @generated from field: optional string language_id = 2;
   */
  languageId?: string;

  constructor(data?: PartialMessage<GetSkyboxStyleFamiliesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetSkyboxStyleFamiliesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSkyboxStyleFamiliesRequest {
    return new GetSkyboxStyleFamiliesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSkyboxStyleFamiliesRequest {
    return new GetSkyboxStyleFamiliesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSkyboxStyleFamiliesRequest {
    return new GetSkyboxStyleFamiliesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSkyboxStyleFamiliesRequest | PlainMessage<GetSkyboxStyleFamiliesRequest> | undefined, b: GetSkyboxStyleFamiliesRequest | PlainMessage<GetSkyboxStyleFamiliesRequest> | undefined): boolean {
    return proto3.util.equals(GetSkyboxStyleFamiliesRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetSkyboxStyleFamiliesResponse
 */
export class GetSkyboxStyleFamiliesResponse extends Message<GetSkyboxStyleFamiliesResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.BlockadeSkyboxStyleFamily families = 1;
   */
  families: BlockadeSkyboxStyleFamily[] = [];

  constructor(data?: PartialMessage<GetSkyboxStyleFamiliesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetSkyboxStyleFamiliesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "families", kind: "message", T: BlockadeSkyboxStyleFamily, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSkyboxStyleFamiliesResponse {
    return new GetSkyboxStyleFamiliesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSkyboxStyleFamiliesResponse {
    return new GetSkyboxStyleFamiliesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSkyboxStyleFamiliesResponse {
    return new GetSkyboxStyleFamiliesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSkyboxStyleFamiliesResponse | PlainMessage<GetSkyboxStyleFamiliesResponse> | undefined, b: GetSkyboxStyleFamiliesResponse | PlainMessage<GetSkyboxStyleFamiliesResponse> | undefined): boolean {
    return proto3.util.equals(GetSkyboxStyleFamiliesResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GenerateSkyboxRequest
 */
export class GenerateSkyboxRequest extends Message<GenerateSkyboxRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 skybox_style_id = 2;
   */
  skyboxStyleId = 0;

  /**
   * @generated from field: string prompt = 3;
   */
  prompt = "";

  /**
   * @generated from field: optional string negative_prompt = 4;
   */
  negativePrompt?: string;

  /**
   * @generated from field: optional string control_image_url = 5;
   */
  controlImageUrl?: string;

  /**
   * @generated from field: bool enhance_prompt = 6;
   */
  enhancePrompt = false;

  /**
   * Override to the user agent language
   *
   * @generated from field: optional string language_id = 7;
   */
  languageId?: string;

  /**
   * The seed used to generate the image; may be set by the returned image when cached
   *
   * @generated from field: optional int32 seed = 8;
   */
  seed?: number;

  constructor(data?: PartialMessage<GenerateSkyboxRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GenerateSkyboxRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "skybox_style_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "prompt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "negative_prompt", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "control_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "enhance_prompt", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "seed", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateSkyboxRequest {
    return new GenerateSkyboxRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateSkyboxRequest {
    return new GenerateSkyboxRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateSkyboxRequest {
    return new GenerateSkyboxRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateSkyboxRequest | PlainMessage<GenerateSkyboxRequest> | undefined, b: GenerateSkyboxRequest | PlainMessage<GenerateSkyboxRequest> | undefined): boolean {
    return proto3.util.equals(GenerateSkyboxRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GenerateSkyboxResponse
 */
export class GenerateSkyboxResponse extends Message<GenerateSkyboxResponse> {
  /**
   * State of the process
   *
   * @generated from field: avn.connect.v1.OperationState state = 1;
   */
  state = OperationState.UNSPECIFIED;

  /**
   * Only set if the job completes successfully
   *
   * @generated from field: optional avn.connect.v1.EntityInfo activity_info = 2;
   */
  activityInfo?: EntityInfo;

  /**
   * May be set when processing to indicated progress
   *
   * @generated from field: optional int32 progress_percent = 3;
   */
  progressPercent?: number;

  constructor(data?: PartialMessage<GenerateSkyboxResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GenerateSkyboxResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(OperationState) },
    { no: 2, name: "activity_info", kind: "message", T: EntityInfo, opt: true },
    { no: 3, name: "progress_percent", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateSkyboxResponse {
    return new GenerateSkyboxResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateSkyboxResponse {
    return new GenerateSkyboxResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateSkyboxResponse {
    return new GenerateSkyboxResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateSkyboxResponse | PlainMessage<GenerateSkyboxResponse> | undefined, b: GenerateSkyboxResponse | PlainMessage<GenerateSkyboxResponse> | undefined): boolean {
    return proto3.util.equals(GenerateSkyboxResponse, a, b);
  }
}

