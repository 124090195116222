// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/organizations.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Authorization } from "./authorization_pb.js";

/**
 * @generated from message avn.connect.v1.JoinOrganizationRequest
 */
export class JoinOrganizationRequest extends Message<JoinOrganizationRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: string join_code = 2;
   */
  joinCode = "";

  constructor(data?: PartialMessage<JoinOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.JoinOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "join_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JoinOrganizationRequest {
    return new JoinOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JoinOrganizationRequest {
    return new JoinOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JoinOrganizationRequest {
    return new JoinOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: JoinOrganizationRequest | PlainMessage<JoinOrganizationRequest> | undefined, b: JoinOrganizationRequest | PlainMessage<JoinOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(JoinOrganizationRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.JoinOrganizationResponse
 */
export class JoinOrganizationResponse extends Message<JoinOrganizationResponse> {
  constructor(data?: PartialMessage<JoinOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.JoinOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JoinOrganizationResponse {
    return new JoinOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JoinOrganizationResponse {
    return new JoinOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JoinOrganizationResponse {
    return new JoinOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: JoinOrganizationResponse | PlainMessage<JoinOrganizationResponse> | undefined, b: JoinOrganizationResponse | PlainMessage<JoinOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(JoinOrganizationResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.CreateNewUserOrganizationRequest
 */
export class CreateNewUserOrganizationRequest extends Message<CreateNewUserOrganizationRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: avn.connect.v1.OrganizationSpec organization_spec = 2;
   */
  organizationSpec?: OrganizationSpec;

  constructor(data?: PartialMessage<CreateNewUserOrganizationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CreateNewUserOrganizationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "organization_spec", kind: "message", T: OrganizationSpec },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateNewUserOrganizationRequest {
    return new CreateNewUserOrganizationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateNewUserOrganizationRequest {
    return new CreateNewUserOrganizationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateNewUserOrganizationRequest {
    return new CreateNewUserOrganizationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateNewUserOrganizationRequest | PlainMessage<CreateNewUserOrganizationRequest> | undefined, b: CreateNewUserOrganizationRequest | PlainMessage<CreateNewUserOrganizationRequest> | undefined): boolean {
    return proto3.util.equals(CreateNewUserOrganizationRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.CreateNewUserOrganizationResponse
 */
export class CreateNewUserOrganizationResponse extends Message<CreateNewUserOrganizationResponse> {
  /**
   * @generated from field: avn.connect.v1.Organization organization = 1;
   */
  organization?: Organization;

  constructor(data?: PartialMessage<CreateNewUserOrganizationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CreateNewUserOrganizationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization", kind: "message", T: Organization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateNewUserOrganizationResponse {
    return new CreateNewUserOrganizationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateNewUserOrganizationResponse {
    return new CreateNewUserOrganizationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateNewUserOrganizationResponse {
    return new CreateNewUserOrganizationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateNewUserOrganizationResponse | PlainMessage<CreateNewUserOrganizationResponse> | undefined, b: CreateNewUserOrganizationResponse | PlainMessage<CreateNewUserOrganizationResponse> | undefined): boolean {
    return proto3.util.equals(CreateNewUserOrganizationResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.Organization
 */
export class Organization extends Message<Organization> {
  /**
   * @generated from field: int32 organization_id = 1;
   */
  organizationId = 0;

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string address = 4;
   */
  address = "";

  /**
   * @generated from field: string post_code = 5;
   */
  postCode = "";

  /**
   * @generated from field: string phone = 6;
   */
  phone = "";

  /**
   * @generated from field: string timezone = 7;
   */
  timezone = "";

  /**
   * @generated from field: string language_id = 8;
   */
  languageId = "";

  /**
   * @generated from field: bool deleted = 9;
   */
  deleted = false;

  /**
   * @generated from field: optional google.protobuf.Timestamp created = 10;
   */
  created?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp updated = 11;
   */
  updated?: Timestamp;

  /**
   * @generated from field: optional string enrollment_secret = 12;
   */
  enrollmentSecret?: string;

  /**
   * @generated from field: string city = 13;
   */
  city = "";

  /**
   * @generated from field: string state = 14;
   */
  state = "";

  /**
   * @generated from field: string country_id = 15;
   */
  countryId = "";

  /**
   * @generated from field: bool publisher = 16;
   */
  publisher = false;

  constructor(data?: PartialMessage<Organization>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Organization";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "post_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "created", kind: "message", T: Timestamp, opt: true },
    { no: 11, name: "updated", kind: "message", T: Timestamp, opt: true },
    { no: 12, name: "enrollment_secret", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "country_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "publisher", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Organization {
    return new Organization().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Organization {
    return new Organization().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Organization {
    return new Organization().fromJsonString(jsonString, options);
  }

  static equals(a: Organization | PlainMessage<Organization> | undefined, b: Organization | PlainMessage<Organization> | undefined): boolean {
    return proto3.util.equals(Organization, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.OrganizationSpec
 */
export class OrganizationSpec extends Message<OrganizationSpec> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string address = 2;
   */
  address = "";

  /**
   * @generated from field: string city = 3;
   */
  city = "";

  /**
   * @generated from field: string state = 4;
   */
  state = "";

  /**
   * @generated from field: string post_code = 5;
   */
  postCode = "";

  /**
   * @generated from field: string country_id = 6;
   */
  countryId = "";

  /**
   * @generated from field: string phone = 7;
   */
  phone = "";

  constructor(data?: PartialMessage<OrganizationSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.OrganizationSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "post_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "country_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrganizationSpec {
    return new OrganizationSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrganizationSpec {
    return new OrganizationSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrganizationSpec {
    return new OrganizationSpec().fromJsonString(jsonString, options);
  }

  static equals(a: OrganizationSpec | PlainMessage<OrganizationSpec> | undefined, b: OrganizationSpec | PlainMessage<OrganizationSpec> | undefined): boolean {
    return proto3.util.equals(OrganizationSpec, a, b);
  }
}

